import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { createStorik } from '@/webapp/src/lib/storik.js'
import { Button, Buttons, Card, FormItems, Modal, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useFormy } from 'svag-formy'
import { z } from 'zod'

export const newJobAudioToSubtitlesModalStorik = createStorik<{
  opened: boolean
  project: ClientProjectForUser
}>({
  defaultValue: {
    opened: false,
    project: null as never as ClientProjectForUser,
  },
})
export const openNewJobAudioToSubtitlesModal = ({ project }: { project: ClientProjectForUser }) => {
  newJobAudioToSubtitlesModalStorik.resetStore({
    opened: true,
    project,
  })
}

// and modal state
// and action to open modal
// modal as component

export const NewJobAudioToSubtitlesModal = () => {
  const formy = useFormy({
    initialValues: {},
    validationSchema: z.object({}),
    resetOnSuccess: true,
    onSubmit: async () => {
      newJobAudioToSubtitlesModalStorik.updateStore({
        opened: false,
      })
    },
    enableReinitialize: true,
  })
  const store = newJobAudioToSubtitlesModalStorik.useStore()
  return (
    <Modal
      opened={store.opened}
      setOpened={(opened) => {
        newJobAudioToSubtitlesModalStorik.updateStore({
          opened,
        })
      }}
      $style={{
        padding: 20,
      }}
    >
      <Segment title="Job Audio To Subtitles">
        <FormItems as="form" {...formy.formProps}>
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Do Job
            </Button>
            <Button
              type="button"
              onClick={() => {
                newJobAudioToSubtitlesModalStorik.updateStore({
                  opened: false,
                })
              }}
            >
              Cancel
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Modal>
  )
}

export const NewJobAudioToSubtitlesPanel = ({ project }: { project: ClientProjectForUser }) => {
  return (
    <Card>
      <Segment title={`Audio To Subtitles`} size="s">
        <Buttons>
          <Button
            onClick={() => {
              openNewJobAudioToSubtitlesModal({ project })
            }}
          >
            Do this job
          </Button>
        </Buttons>
      </Segment>
    </Card>
  )
}
