import { Artifacts } from '@/general/src/artifact/components/Artifacts.js'
import { Jobs } from '@/general/src/job/components/Jobs.js'
import { NewJob } from '@/general/src/job/components/NewJob.js'
import { ProjectHeader } from '@/general/src/project/components/ProjectHeader.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { userProjectViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const UserProjectViewPage = withPageWrapper({
  Layout: GeneralLayout,
  authorizedUsersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(userProjectViewRoute)
    return trpc.getProjectForUser.useQuery({
      projectSn: +routeParams.projectSn,
    })
  },
  setProps: ({ queryResult }) => ({
    project: queryResult.data.project,
  }),
  title: ({ queryResult }) => `Project #${queryResult.data.project.sn}`,
})(({ project }) => {
  return (
    <Block fcnw g={30}>
      <ProjectHeader project={project} />
      <Artifacts project={project} />
      <Jobs project={project} />
      <NewJob project={project} />
    </Block>
  )
})
