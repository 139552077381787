/* eslint-disable no-unreachable-loop */
import { ArtifactUpload, type ArtifactUploadStep } from '@/general/src/artifact/components/ArtifactUpload.js'
import type { ClientArtifactFile, ClientArtifactGeneral } from '@/general/src/artifact/utils.server.js'
import { getArtifactFileDownloadUrl } from '@/general/src/artifact/utils.shared.js'
import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Card, Informer, LabeledValues, Loader, Segment } from '@/webapp/src/lib/uninty.components.js'
import type { LabeledValuesItem } from '@uinity/react-dom/dist/components/LabeledValue/clear.js'
import { useState } from 'react'

const artifactFileToLabeledValuesItems = (af: ClientArtifactFile): LabeledValuesItem[] => {
  return [
    { value: af.type, label: 'File Type' },
    { value: af.sizeBytes, label: 'Size Bytes' },
    { value: af.originalFileBaseName, label: 'Original File Base Name' },
    { value: af.mimeType, label: 'Mime Type' },
    { value: af.ext, label: 'Ext' },
    {
      value: (
        <a href={getArtifactFileDownloadUrl({ artifactFileId: af.id })} target="_blank">
          {af.s3Key}
        </a>
      ),
      label: 'S3 Key',
    },
  ]
}

const Artifact = ({ artifactGeneral: ag }: { artifactGeneral: ClientArtifactGeneral }) => {
  const labeledValuesItems: LabeledValuesItem[] = [
    { value: ag.id, label: 'ID' },
    { value: ag.sn, label: 'SN' },
    { value: ag.createdAt.toISOString(), label: 'Created At' },
    { value: ag.updatedAt.toISOString(), label: 'Updated At' },
    { value: ag.readyAt?.toISOString(), label: 'Ready At' },
    { value: ag.failAt?.toISOString(), label: 'Fail At' },
    // {value: ag.indexUnique,  label: 'Index unique'},
    // {value: ag.indexSimilar,  label: 'Index similar'},
    { value: ag.createType, label: 'Create Type' },
    { value: ag.type, label: 'Artifact Type' },
    { value: ag.status, label: 'Status' },
  ]
  for (const aa of ag.artifactAudios) {
    labeledValuesItems.push(
      ...[
        { value: aa.isBackground, label: 'Is Background' },
        { value: aa.isEnhanced, label: 'Is Enhanced' },
        { value: aa.durationMs, label: 'Duration Ms' },
        { value: aa.lang, label: 'Lang' },
      ]
    )
    for (const af of aa.artifactFiles) {
      labeledValuesItems.push(...artifactFileToLabeledValuesItems(af))
      break
    }
    break
  }
  for (const av of ag.artifactVideos) {
    labeledValuesItems.push(
      ...[
        { value: av.width, label: 'Width' },
        { value: av.height, label: 'Height' },
        { value: av.durationMs, label: 'Duration Ms' },
        { value: av.langs, label: 'Langs' },
      ]
    )
    for (const af of av.artifactFiles) {
      labeledValuesItems.push(...artifactFileToLabeledValuesItems(af))
      break
    }
    break
  }
  return (
    <Card>
      <LabeledValues items={labeledValuesItems} />
    </Card>
  )
}

export const Artifacts = ({ project }: { project: ClientProjectForUser }) => {
  const [uploadStep, setUploadStep] = useState<ArtifactUploadStep>('none')
  const qr = trpc.getArtifacts.useQuery(
    { projectId: project.id },
    {
      refetchInterval: 1000,
    }
  )
  const artifactGenerals = qr.data?.artifactGenerals ?? []
  const showUploadButton = (!qr.isLoading && !artifactGenerals.length) || uploadStep === 'uploading'
  return (
    <Segment title={`Artifacts`} desc={`Files of your project`} size="m">
      <Block fcnw g={20}>
        {showUploadButton && <ArtifactUpload project={project} setStep={setUploadStep} />}
        {qr.isLoading ? (
          <Loader />
        ) : qr.isError ? (
          <Informer color="red">{qr.error.message}</Informer>
        ) : artifactGenerals.length === 0 ? (
          <p>No artifacts</p>
        ) : (
          <Block fcnw g={20}>
            {artifactGenerals.map((artifactGeneral) => (
              <Artifact key={artifactGeneral.id} artifactGeneral={artifactGeneral} />
            ))}
          </Block>
        )}
      </Block>
    </Segment>
  )
}
