import {
  NewJobAudioToSubtitlesModal,
  NewJobAudioToSubtitlesPanel,
} from '@/general/src/job/audioToSubtitles/components/NewJobAudioToSubtitles.js'
import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const NewJob = ({ project }: { project: ClientProjectForUser }) => {
  return (
    <>
      <Segment title={`New Job`} size="m">
        <Block fcnw g={20}>
          <NewJobAudioToSubtitlesPanel project={project} />
        </Block>
      </Segment>
      <NewJobAudioToSubtitlesModal />
    </>
  )
}
