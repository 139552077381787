import type { ClientJobGeneralForUser } from '@/general/src/job/utils.server.js'
import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Card, Informer, LabeledValues, Loader, Segment } from '@/webapp/src/lib/uninty.components.js'
import type { LabeledValuesItem } from '@uinity/react-dom/dist/components/LabeledValue/clear.js'

const Job = ({ jobGeneral: jg }: { jobGeneral: ClientJobGeneralForUser }) => {
  const labeledValuesItems: LabeledValuesItem[] = [
    { value: jg.id, label: 'ID' },
    { value: jg.sn, label: 'SN' },
    { value: jg.createdAt.toISOString(), label: 'Created At' },
    { value: jg.updatedAt.toISOString(), label: 'Updated At' },
    { value: jg.costTokensApproximate, label: 'Cost Tokens Approximate' },
    { value: jg.costTokensReal, label: 'Cost Tokens Real' },
    { value: jg.status, label: 'Status' },
    { value: jg.doNotTake, label: 'Do Not Take' },
    { value: jg.type, label: 'Type' },
    { value: jg.serverKey, label: 'Server Key' },
    { value: jg.failureReason, label: 'Failure Reason' },
    { value: jg.errorData && JSON.stringify(jg.errorData), label: 'Error Data' },
    { value: jg.errorMessage, label: 'Error Message' },
    { value: jg.startUploadingToServerAt?.toISOString(), label: 'Start Uploading To Server At' },
    { value: jg.uploadedToServerAt?.toISOString(), label: 'Uploaded To Server At' },
    { value: jg.startUploadingToStorageAt?.toISOString(), label: 'Start Uploading To Storage At' },
    { value: jg.uploadedToStorageAt?.toISOString(), label: 'Uploaded To Storage At' },
    { value: jg.startProcessingAt?.toISOString(), label: 'Start Processing At' },
    { value: jg.processedAt?.toISOString(), label: 'Processed At' },
    { value: jg.startAwaitingForDepsAt?.toISOString(), label: 'Start Awaiting For Deps At' },
    { value: jg.awaitedForDepsAt?.toISOString(), label: 'Awaited For Deps At' },
    { value: jg.startAwaitingForWorkerAt?.toISOString(), label: 'Start Awaiting For Worker At' },
    { value: jg.awaitedForWorkerAt?.toISOString(), label: 'Awaited For Worker At' },
    { value: jg.successAt?.toISOString(), label: 'Success At' },
    { value: jg.failAt?.toISOString(), label: 'Fail At' },
  ]
  return (
    <Card>
      <LabeledValues items={labeledValuesItems} />
    </Card>
  )
}

export const Jobs = ({ project }: { project: ClientProjectForUser }) => {
  const qr = trpc.getJobs.useQuery(
    { projectId: project.id },
    {
      refetchInterval: 1000,
    }
  )
  const jobGenerals = qr.data?.jobGenerals ?? []
  return (
    <Segment title={`Jobs`} size="m">
      <Block fcnw g={20}>
        {qr.isLoading ? (
          <Loader />
        ) : qr.isError ? (
          <Informer color="red">{qr.error.message}</Informer>
        ) : jobGenerals.length === 0 ? (
          <p>No jobs</p>
        ) : (
          <Block fcnw g={20}>
            {jobGenerals.map((jobGeneral) => (
              <Job key={jobGeneral.id} jobGeneral={jobGeneral} />
            ))}
          </Block>
        )}
      </Block>
    </Segment>
  )
}
